import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function SimpleAlerts() {
  const classes = useStyles();

  return (
    <div className={classes.root} >
      <Alert variant="standard" severity="error">
        Devido às novas regras de emissão de recibo da Receita Federal, informamos que o VisuPsi
        será descontinuado e não fará mais emissão de recibos a partir de 01/03/2025.
        Os recibos poderão ser baixados ate 01/06/2025.
      </Alert>
    </div>
  );
}
